import React, { useEffect, useRef, useState } from "react";
import CopyIcon from "../../../../../../../../../../shared/icons/copyicon";
import LikeIcon from "../../../../../../../../../../shared/icons/likeicon";
import DislikeIcon from "../../../../../../../../../../shared/icons/dislikeicon";
import BotLogo from "../../../../../../../../../../assets/images/icons/botlogo.svg";
import ImageResults from "../imageresult";
import LearnMore from "../learnmore";

const  ContentSection = ({item, onDislikeClick , onLikeClick, }) => {
    
   const textRef = useRef()

   const copyToClipboard = () => {
    const htmlContent = textRef.current.innerHTML;

    try {
       navigator.clipboard.write([
        // eslint-disable-next-line no-undef
        new ClipboardItem({
          "text/html": new Blob([htmlContent], { type: "text/html" }),
          "text/plain": new Blob([textRef.current.innerText], { type: "text/plain" }),
        }),
      ]);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
  }; 


    return (
        <div className="query-output-row d-flex">
                        <div className="query-bot-logo">
                          <img src={BotLogo} alt="" />
                        </div>
                        <div className="query-output-message">
                          {/* <p className="m-0">{item?.message}</p> */}
                          <div className="query-output-message-flex-wrap">
                            <div className="query-output-box">
                              <div className="query-output-paragraph-wrap">
                              <div ref={textRef} className={item?.is_answer_links_valid ? "styledlink formatingParagraph" : "m-0 formatingParagraph"} dangerouslySetInnerHTML={{ __html: item?.message }} /></div>
                              {item?.indicators?.length > 0 &&  <ImageResults indicators={item?.indicators} />}
                              {item?.references?.length > 0 && <LearnMore references={item?.references} /> }
                            </div>
                          </div>
                        </div>
                        {item?.message==="Server Error: We apologize, but our service is currently experiencing technical difficulties. Please try again later."||
                       item?.message=== "It seems like your message is empty. If you have any questions or need assistance with something, please feel free to ask, and I'll be happy to help!"||
                       item?.message==="It seems like your message is incomplete. How can I assist you today? Please provide more details or let me know what you'd like to discuss or ask about, and I'll be happy to help."?null: <div className="query-output-action-icons">
                          <span onClick={() => copyToClipboard()} data-tooltip-id="my-tooltip-click" className="query-action-icon query-action-copy">
                            <CopyIcon />
                          </span>
                          <span onClick={()=>{item?.like!==true&&
                            onLikeClick(item)
                          }} className="query-action-icon query-action-like">
                            <LikeIcon iconColor = {item?.like===true?"#1A73E8":"#373737"} />
                          </span>
                          <span onClick = {()=>{item?.dislike!==true&&
                            onDislikeClick(item)
                          }} className="query-action-icon query-action-dislike">
                            <DislikeIcon iconColor = {item?.dislike===true?"#1A73E8":"#373737"}/>
                          </span>
                         
                          
                        </div>}
                       
                      </div>
    )
}

export default ContentSection