import React, { useEffect, useState,useRef } from 'react'
import { Button, Container } from 'react-bootstrap';
import Header from '../../../shared/header';
import Banner from '../components/banner/banner';
import Devpubs from '../components/devpubs/devpubs';
import Financing from '../components/financing/financing';
import MultiMedia from '../components/multimedia/multimedia';
import NewsSec from '../components/news/news';
import Otherpubs from '../components/otherpubs/otherpubs';
import Econpubs from '../components/econpubs/econpubs';
import Evaluations from '../components/evaluations/evaluations';
import { userRecomendation, UserListSaveQuery, setCurrentCategory, setSearchKey } from './../../results/services'
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import upgradeImg from '../../../assets/images/icons/upgrade-icon.png'
import {  Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { userTierInfo } from '../../profile/services';

const Home = () => {
  const navigate = useNavigate()
  const result  = useSelector((state)=>state?.results?.userRecomendations?.error?.response?.data?.message,shallowEqual)
  // console.log(result,"result");
  const loading = useSelector(
    (state) => state?.results?.userRecomendations?.loading
  );
  const recomendations_state = useSelector((state)=>state?.results?.userRecomendations,shallowEqual)
  const recomendations_ref = useRef(recomendations_state)
  recomendations_ref.current = recomendations_state
  const [NoresultsShow, NoresultsSetShow] = useState(false);
  const NoresultshandleClose = () => NoresultsSetShow(false);
  const NoresultshandleShow = () => NoresultsSetShow(true);

  const [WentWrongShow, WentWrongSetShow] = useState(false);
  const WentWronghandleClose = () => WentWrongSetShow(false);
  const WentWronghandleShow = () => WentWrongSetShow(true);
  const [error,setError] = useState(false)
  // console.log(error,"error");
  useEffect(()=>{
    if(result!==undefined&&!loading){
      setError(true)
    }
  },[result])

  useEffect(()=>{
    if(result===undefined){
      setError(false)
    }
  },[result])

  useEffect(()=>{
    if(error){
      setTimeout(()=>{
        WentWronghandleShow()
      },3000)
    }else{
      WentWronghandleClose()
    }
  },[error])

  

  const token = useSelector((store) => store?.auth?.signin?.response?.data?.data?.token, shallowEqual)
  const tokenSso = useSelector(
    (state) => state?.auth?.sso_login_callback?.response?.data?.data?.token, shallowEqual
  );
  const dispatch = useDispatch()
  const userTierInformation =()=>{
    if(token!==undefined){
      dispatch(userTierInfo(token))
    }else if(tokenSso!==undefined){
      dispatch(userTierInfo(tokenSso))
    }
  }
  const showRecomendations = async () => {
    if (token !== undefined) {
      dispatch(userRecomendation(token))
    } else if (tokenSso !== undefined) {
      dispatch(userRecomendation(tokenSso))
    }
  }
  useEffect(() => {
    showRecomendations()
    // userTierInformation()

  }, [])

  useEffect(() => {
    setCurrentCategory("financing")
    dispatch(setSearchKey(""))
  }, [])
  const state = useSelector((state) => state?.results)
  const userList = () => {
    if (token !== undefined) {
      dispatch(UserListSaveQuery(token, "", "", "", "","False"))
    } else if (tokenSso !== undefined) {
      dispatch(UserListSaveQuery(tokenSso, "", "", "", "","False"))
    }
  }

  useEffect(() => {
    userList()
  }, [])

  return (
    <div className="main-container">
      <Helmet>
        <title>DevDiscovery | Home</title>
      </Helmet>
      <Banner />
      <div className="home-content-area">
        <Container>
          <h2 className="sec-title m-b-25">Recommended for you</h2>
          <section className="hom-secs m-b-20">
            <Financing />
          </section>
          <section className="hom-secs m-b-20">
            <NewsSec />
          </section>
          <section className="hom-secs m-b-20">
            <Devpubs />
          </section>
          <section className="hom-secs m-b-20">
            <Econpubs />
          </section>
          <section className="hom-secs m-b-20">
            <MultiMedia />
          </section>
          <section className="hom-secs m-b-20">
            <Otherpubs />
          </section>
          <section className="hom-secs m-b-20">
            <Evaluations />
          </section>
        </Container>
      </div>
      {/* <div className='popup-btns'>
        <Button onClick={NoresultshandleShow}>500</Button> 
        <Button onClick={WentWronghandleShow}>200</Button>
      </div> */}
      <Modal show={NoresultsShow} size="sm" centered className='common-modal'>
      <Modal.Header closeButton onClick={NoresultshandleClose}/>
        <Modal.Body>
          <div className="upgrade-pop-wrap no-results">
            <div className='upgrade-img'>
              <img src={upgradeImg} alt="" />
            </div>
            <h3 className='upgrade-title'>Sorry! No Results found.</h3>
            {/* <p>Sorry! No Results found.</p> */}
            <div className='upgrade-btn'>
              <Button size='sm' onClick={NoresultshandleClose}>Close</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={WentWrongShow} size="sm" centered className='common-modal reload-popup'>
      <Modal.Header closeButton onClick={WentWronghandleClose}/>
        <Modal.Body>
          <div className="upgrade-pop-wrap no-results">
            <div className='upgrade-img'>
              <img src={upgradeImg} alt="" />
            </div>
            <h3 className='upgrade-title'>Unable to pull recommendations for you at the moment. Please try again!</h3>
            {/* <p>Something went wrong, please refresh!</p> */}
            <div className='upgrade-btn'>
              <Button size='sm' onClick={()=>{
                window.location.reload()
              }}>Reload</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Home;