export const personalities = [
    { title: "Web Searcher", value:'web', description: "Tap into real-time insights from across the web to inform your development research." },
    { title: "Financing Insights",value:'financing', description: "Design projects and programs with detailed components, activities, and implementation plans." },
    { title: "Publication Lens", value:'devpubs',description: "Conduct in-depth research, generate insights, and enhance your understanding of development topics." },
    { title: "Open Inquiry", value:'open',description: "Explore any topic in a flexible, open-ended format—ideal for brainstorming and broad inquiries." },
    { title: "Economy Notes", value:'economy',description: "Create comprehensive economic briefings using structured data from reliable sources." },
    { title: "Impact Insights", value:'evaluations',description: "Unlock lessons from impact evaluations to drive evidence-based decisions." },
    { title: "Sector Notes", value:'sector',description: "Generate sector-specific briefs and comparative analyses using current data." },
    { title: "Document Analyzer", value:'document',description: " Upload your documents to generate summaries and extract insights." },
  ];

  export const relatedArticlesCategory = {
    web: "news",
    financing: "financing",
    devpubs: "devpubs",
    open: "",
    economy: "econpubs",
    evaluations: "evaluations",
    sector: "devpubs",
    document: "",
  }

    // const options = [
  //   { value: "devpubs", label: "DevPub Notes" },
  //   { value: "economy", label: "Economy Notes" },
  //   { value: "financing", label: "Financing Insights" },
  //   { value: "sector", label: "Sector Notes" },
  //   { value: "factoids", label: "Wiki Factoids" },
  // ];

export const stopWords = [
    "a",
    "an",
    "the",
    "in",
    "on",
    "at",
    "to",
    "with",
    "is",
    "as",
    "it",
    "and",
    "or",
    "but",
    "of",
    "for",
    "by",
    "from",
    "that",
    "this",
    "which",
    "was",
    "were",
    "been",
    "are",
    "have",
    "has",
    "do",
    "does",
    "did",
    "not",
    "I",
    "you",
    "he",
    "she",
    "it",
    "we",
    "they",
    "my",
    "your",
    "his",
    "its",
    "our",
    "their",
    "me",
    "him",
    "us",
    "them",
    "am",
    "is",
    "are",
    "was",
    "were",
    "be",
    "being",
    "been",
    "will",
    "shall",
    "can",
    "could",
    "should",
    "would",
    "may",
    "might",
    "must",
    "ought",
    "shall",
    "will",
    "now",
    "then",
    "so",
    "well",
  ];



  